import { Button, IconButton } from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import Link from '@mui/material/Link'
import Tooltip from '@mui/material/Tooltip'
import React from 'react'
import { Icon } from '../icon'

import {
  Container,
  LeftSection,
  RightCompButton,
  RightCompText,
  RightSection,
  RightSideSeparator,
} from './styled'

import themeVar from '../../theme/themeVar'

interface BreadCrumbItemType {
  text: string
  href?: string
  onClick?: () => void
}

type TooltipProps = WithCustomTooltipProps & DefaultTooltipProps

interface WithCustomTooltipProps {
  CustomTooltipComponent?: React.ReactNode
}

interface DefaultTooltipProps {
  defaultTooltipProps?: Omit<React.ComponentProps<typeof Tooltip>, 'children'>
}

type RightComponentsType = TextCompProps | ButtonCompProps | CustomCompProps
interface TextCompProps {
  type: 'text'
  text: string
  containerProps?: Omit<
    React.DetailedHTMLProps<
      React.HTMLAttributes<HTMLDivElement>,
      HTMLDivElement
    >,
    'ref'
  >
}
interface ButtonCompProps {
  type: 'button'
  text: string
  containerProps?: React.ComponentProps<typeof Button>
}
interface CustomCompProps {
  type: 'custom'
  text: string
  renderComponent: (text: string, index: number) => React.ReactNode
}

export type HeaderProps<CustomItemProp> = {
  breadcrumbItems: BreadCrumbItemType[]
  showPrevButton?: boolean
  prevButtonProps?: React.ComponentProps<typeof IconButton>
  RightComponents?: RightComponentsType[]
  BreadcrumbItemComp?: React.ComponentType<CustomItemProp>
  breadcrumbItemCompProps?: CustomItemProp
  defaultBreadcrumbItemProps?: React.ComponentProps<typeof Link>
} & TooltipProps

/**
 *
 * @param  breadcrumbItems - Array of breadcrumb items shown on the left side.
 * @param defaultBreadcrumbItemProps - Props for the default breadcrumb item (Link).
 * @param  showPrevButton - Show the prev page button.
 * @param  prevButtonProps - Props for the prev page button.
 * @param  CustomTooltipComponent - Custom tool tip component.
 * @param  defaultTooltipProps - Props for the default tooltip. Default tooltip will render if props are provided and without custom component.
 * @param  RightComponents - Components at the right side.
 * @param BreadcrumbItemComp - Custom breadcrumb component.
 * @param breadcrumbItemCompProps - Props for the custom breadcrumb components
 */

export const Header = <CustomItemProp extends {}>(
  props: HeaderProps<CustomItemProp>
) => {
  const {
    breadcrumbItems,
    defaultBreadcrumbItemProps,
    showPrevButton = true,
    prevButtonProps,
    CustomTooltipComponent,
    defaultTooltipProps,
    RightComponents,
    BreadcrumbItemComp,
    breadcrumbItemCompProps,
  } = props

  return (
    <Container>
      <LeftSection>
        {!showPrevButton ? null : (
          <IconButton
            size="large"
            sx={{ height: '1.25rem', width: '1.25rem' }}
            {...prevButtonProps}
          >
            <Icon
              type="arrow_back"
              style={{ color: themeVar.color.palette['grayscale-800'] }}
              size={18}
            />
          </IconButton>
        )}

        <Breadcrumbs aria-label="breadcrumbs">
          {breadcrumbItems.map((bcItem, index) => {
            if (BreadcrumbItemComp) {
              return (
                <BreadcrumbItemComp
                  onClick={bcItem.onClick}
                  {...(breadcrumbItemCompProps as CustomItemProp)}
                  href={bcItem.href}
                  key={`bc_${bcItem.text}_${index}`}
                >
                  {bcItem.text}
                </BreadcrumbItemComp>
              )
            } else {
              if (!bcItem.href) {
                return (
                  <div
                    onClick={bcItem.onClick}
                    style={{
                      fontSize: '0.875rem',
                      cursor: bcItem.onClick ? 'pointer' : 'initial',
                    }}
                    key={`bc_${bcItem.text}_${index}`}
                  >
                    {bcItem.text}
                  </div>
                )
              } else {
                return (
                  <Link
                    onClick={bcItem.onClick}
                    color="inherit"
                    href={bcItem.href}
                    sx={{ fontSize: '0.875rem' }}
                    {...defaultBreadcrumbItemProps}
                    key={`bc_${bcItem.text}_${index}`}
                    underline="hover"
                  >
                    {bcItem.text}
                  </Link>
                )
              }
            }
          })}
        </Breadcrumbs>

        {CustomTooltipComponent ??
          (defaultTooltipProps ? (
            <Tooltip {...defaultTooltipProps}>
              <IconButton size="small">
                <Icon
                  type="info"
                  style={{ color: themeVar.color.palette['grayscale-300'] }}
                  size={16}
                />
              </IconButton>
            </Tooltip>
          ) : null)}
      </LeftSection>

      {RightComponents ? (
        <RightSection>
          <Breadcrumbs
            aria-label="right components"
            separator={<RightSideSeparator />}
          >
            {RightComponents.map((component, index) => {
              switch (component.type) {
                case 'text': {
                  return (
                    <RightCompText
                      {...component.containerProps}
                      key={`${component.text}_${index}`}
                    >
                      {component.text}
                    </RightCompText>
                  )
                }
                case 'button': {
                  return (
                    <RightCompButton
                      color="inherit"
                      sx={{ textTransform: 'none' }}
                      {...component.containerProps}
                      key={`${component.text}_${index}`}
                    >
                      {component.text}
                    </RightCompButton>
                  )
                }
                case 'custom': {
                  return (
                    <RightCompText key={`${component.text}_${index}`}>
                      {component.renderComponent(component.text, index)}
                    </RightCompText>
                  )
                }
                default:
                  return null
              }
            })}
          </Breadcrumbs>
        </RightSection>
      ) : null}
    </Container>
  )
}
