import styled from '@emotion/styled'
import { Button } from '@mui/material'

import themeVar from '../../theme/themeVar'

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
`
export const LeftSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  .MuiBreadcrumbs-li {
    &:last-child {
      color: ${themeVar.color.palette['primary-500']};
    }
    &:only-child {
      color: ${themeVar.color.palette['grayscale-800']};
    }
    color: ${themeVar.color.palette['grayscale-800']};
  }
  .MuiBreadcrumbs-separator {
    color: ${themeVar.color.palette['grayscale-800']};
  }
`
export const RightSection = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  line-height: 1.25rem;
  font-size: 0.875rem;
  .MuiBreadcrumbs-li {
    color: ${themeVar.color.palette['grayscale-800']};
  }
`

export const RightSideSeparator = styled.div`
  width: 1px;
  height: 1.25rem;
  background-color: ${themeVar.color.palette['grayscale-100']};
`
export const RightCompText = styled.div`
  font-size: 0.875rem;
  line-height: 1.25rem;
  padding: 0.375rem 0.5rem;
  color: ${themeVar.color.palette['grayscale-800']};
`
export const RightCompButton = styled(Button)`
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: ${themeVar.color.palette['grayscale-800']};
`
