import * as React from 'react'

import { Container } from './styled'

export interface BuyingOptionItem {
  name: string
  salePrice: string
}

export interface BuyingOptionProps {
  data: BuyingOptionItem
  onSelect?: (opt: BuyingOptionItem) => void
  isSelected?: boolean
  isSelectable?: boolean

  nameElement?: React.ReactElement
  infoElement?: React.ReactElement
  startElement?: React.ReactElement
  endElement?: React.ReactElement
}

export const BuyingOption: React.FC<BuyingOptionProps> = ({
  data,
  onSelect,
  isSelected = false,
  isSelectable = false,
  nameElement,
  infoElement,
  startElement,
  endElement,
}) => {
  const handleClick = () => {
    if (onSelect) onSelect(data)
  }

  return (
    <Container isSelected={isSelected}>
      {startElement && (
        <div className="start">
          {React.cloneElement(startElement, { data, isSelected })}
        </div>
      )}

      <button
        className="sale-info"
        onClick={handleClick}
        disabled={!isSelectable}
      >
        {nameElement && (
          <div className="name">
            {React.cloneElement(nameElement, { name: data.name })}
          </div>
        )}

        <p className="price">{`NT $${data.salePrice}`}</p>

        {infoElement && (
          <div className="detail">
            {React.cloneElement(infoElement, { data, isSelected })}
          </div>
        )}
      </button>

      {endElement && (
        <div className="end">
          {React.cloneElement(endElement, { data, isSelected })}
        </div>
      )}
    </Container>
  )
}
