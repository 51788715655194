import { isProd, STAGE } from '@/libs/env'

const SENTRY_DSN = process.env.NEXT_PUBLIC_SENTRY_DSN

const config = {
  dsn: SENTRY_DSN,
  tracesSampleRate: isProd ? 0.1 : 1.0,
  profilesSampleRate: isProd ? 0.1 : 1.0,
  debug: isProd ? false : process.env.NEXT_PUBLIC_SENTRY_DEBUG === 'true',
  environment: STAGE,
  // ...
  // Note: if you want to override the automatic release value, do not set a
  // `release` value here - use the environment variable `SENTRY_RELEASE`, so
  // that it will also get attached to your source maps
}

export default config
