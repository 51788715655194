import React from 'react'

import { BUTTON_RESET_STYLE, GRADIENT_TEXT_STYLE } from './constants'

const BUTTON_STYLE = {
  flexGrow: 1,
  padding: '5.5px 0',
  borderRadius: '1rem',
}
const ButtonWithGradient = ({
  isDisabled = false,
  onClick,
  children,
}: {
  isDisabled?: boolean
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  children: React.ReactNode
}) => {
  if (isDisabled) {
    return (
      <button
        type="button"
        style={{
          ...BUTTON_RESET_STYLE,
          ...BUTTON_STYLE,
          color: '#BDC0C0', // Grayscale/300
          border: '1px solid #BDC0C0', // Grayscale/300
          cursor: 'not-allowed',
        }}
      >
        {children}
      </button>
    )
  }

  return (
    <button
      type="button"
      onClick={onClick}
      style={{
        ...BUTTON_RESET_STYLE,
        ...BUTTON_STYLE,
        padding: 1,
        background: 'linear-gradient(225deg, #72AFFF 0%, #C890FF 100%)',
      }}
    >
      <div
        style={{
          padding: 5.5,
          borderRadius: '1rem',
          backgroundColor: '#ffffff',
        }}
      >
        <span style={GRADIENT_TEXT_STYLE}>{children}</span>
      </div>
    </button>
  )
}

export default ButtonWithGradient
