import getGtagBasicEventParams from '@/features/tracking/gtag/getGtagBasicEventParams'

const PIXEL_ID = process.env.NEXT_PUBLIC_FB_PIXEL_ID

const initPixel = (pixelId: string | undefined, options = {}) => {
  window.fbq('init', pixelId, options)
}

const internal = {
  trackSingle: (name: string, params: object | undefined = {}, options?: object) => {
    window.fbq('trackSingle', PIXEL_ID, name, { ...getGtagBasicEventParams(), ...params }, options)
  },
}

const external = {
  pageView: (pixelId: string) => {
    window.fbq('trackSingle', pixelId, 'PageView')
  },
  trackSingle: (pixelId: string, name: string, params: object) => {
    if (!pixelId) return
    window.fbq('trackSingle', pixelId, name, params)
  },
}

export const PIXEL_CONVERSION_API_PARAMS = 'fbclid' as const
export default {
  initPixel,
  internal,
  external,
}
