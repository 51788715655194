import React, { useMemo } from 'react'

import themeVar from '../../../theme/themeVar'
import { processMaterialChapter } from '../utils'
import type { MaterialTocChapterType, OnPreviewUnitClickType } from '../types'

import TocUnit from './tocUnit'
import { Text } from '../../text'

interface TocChapterProps {
  chapter: MaterialTocChapterType
  onPreviewUnitClick?: OnPreviewUnitClickType
  t: any
  materialId: string
  subjectLanguage?: string
}

const Chapter = ({
  chapter,
  onPreviewUnitClick,
  t,
  materialId,
  subjectLanguage,
}: TocChapterProps) => {
  const sections = useMemo(
    () => processMaterialChapter(chapter),
    [chapter.children]
  )

  return (
    <div>
      {sections.map((section, sectionIdx) => {
        const { name: sectionName, children: units } = section
        const isLastItem = sections.at(-1) === section
        const divBottom = isLastItem ? 0 : themeVar.distance.m

        return (
          <div
            key={`${sectionName}-${sectionIdx}`}
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: '8px',
              marginBottom: divBottom,
              paddingBottom: divBottom,
              borderBottom: isLastItem ? 'none' : '1px solid #eeefef',
            }}
          >
            <Text variant="label_s_400" color="grayscale-500">
              {`${chapter.name} / ${sectionName}`}
            </Text>

            <TocUnit
              t={t}
              units={units}
              section={section}
              materialId={materialId}
              onPreviewUnitClick={onPreviewUnitClick}
              subjectLanguage={subjectLanguage}
            />
          </div>
        )
      })}
    </div>
  )
}

export default Chapter
