import styled from '@emotion/styled'
import React, { FC } from 'react'
import LineEllipsis from 'react-lines-ellipsis'
import Stars from 'react-stars'

import { Img } from '../img'
import { Icon } from '../icon'
import { useTranslation } from 'react-i18next'

const Container = styled.div`
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
`

const CardImage = styled.div<{ isSkeleton?: boolean }>`
  position: relative;
  outline: none;
  flex: none;
  width: 100%;
  height: 145px;

  img {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    object-fit: cover;
    aspect-ratio: 258 / 143;
  }

  @media (min-width: 600px) {
    height: 160px;
  }

  ${({ isSkeleton }) => isSkeleton && `background: #f2f2f2;`}
`

const CardLink = styled.a<{ isStatic?: boolean }>`
  box-sizing: border-box;
  margin-bottom: 14px;
  display: block;
  width: 100%;
  height: auto;
  color: #383b3d !important;
  pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'fill')};
  text-decoration: none;

  img picture {
    pointer-events: ${({ isStatic }) => (isStatic ? 'none' : 'auto')};
  }
`

const CardBody = styled.div`
  box-sizing: border-box;
  flex: auto;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 16px;
  gap: 12px;
`

const CardTitle = styled(CardLink)`
  min-width: 180px;
  font-weight: 500;
  margin: 0;

  h4 {
    margin: 0;
    font-size: 16px;
  }
`

const CardSlogan = styled.p`
  margin: 0;
  font-size: 14px;
  width: 100%;
  min-width: 180px;
  font-weight: 400;
  color: #7c8282;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const CardAuthor = styled(CardSlogan)`
  color: #9da1a1;
`

const CardRating = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  font-size: 14px;

  .staring {
    display: inline-block;
    margin-right: 2px;
  }

  span {
    display: inline-block;
    margin-right: 2px;
  }
`

const CardContentSkeleton = styled.div`
  width: 100%;
  height: 14px;
  margin-bottom: 4px;
  background-color: #f2f2f2;
`

const CardLearnWith = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  margin: 0;
  color: #72afff;
  font-size: 14px;
`

export interface ProductCardProps {
  isSkeleton?: boolean
  isStatic?: boolean
  className?: string

  attrs: {
    productable_id: string
    name: string
    slogan?: string | null
    learn_with?: string | null
    list_price?: string
    sale_price?: string
    copies_sold?: number | null
    allow_trial?: boolean
    show_rating: boolean
    rating_count?: number | null
    average_rating?: number | null
    shop_cover_image_url?: string | null
    author_name: string | null
    publisher_name: string | null
  }
  domain?: string
  getPath?: (path: string) => string
}

export const ProductCard: FC<React.PropsWithChildren<ProductCardProps>> = ({
  children,
  className,
  isSkeleton = false,
  isStatic = false,
  attrs,
  domain = 'https://public.wordup.com.tw',
  getPath = (id) => `/products/${id}`,
}) => {
  const { t } = useTranslation()

  return (
    <Container className={className}>
      <CardImage tabIndex={0} isSkeleton={isSkeleton}>
        <CardLink isStatic={isStatic} href={getPath(attrs.productable_id)}>
          {!isSkeleton && attrs.shop_cover_image_url && (
            <Img
              domain={domain}
              filePaths={[
                attrs.shop_cover_image_url,
                attrs.shop_cover_image_url,
              ]}
              alt={attrs.name}
              sizes={[
                {
                  width: 300,
                  device: 900,
                },
                {
                  width: 200,
                  device: 600,
                },
              ]}
            />
          )}
        </CardLink>
      </CardImage>

      <CardBody>
        {!isSkeleton ? (
          <>
            <CardLearnWith>
              <Icon type="group" />
              {`${attrs.learn_with || 0} ${t(
                'crowdin:wu-components.product-card.students-learn-together'
              )}`}
            </CardLearnWith>

            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <CardTitle
                isStatic={isStatic}
                href={getPath(attrs.productable_id)}
              >
                <LineEllipsis text={attrs.name} component="h4" maxLine={2} />
              </CardTitle>

              {attrs.slogan && <CardSlogan>{attrs.slogan} </CardSlogan>}
            </div>

            {attrs.show_rating && (
              <CardRating>
                <div className="staring">
                  <Stars
                    edit={false}
                    count={5}
                    value={attrs.average_rating || 0}
                    size={14}
                    color1="#e5e5e5"
                    color2="#ffc914"
                  />
                </div>
                ({attrs.rating_count})
              </CardRating>
            )}
            {attrs.author_name && (
              <CardAuthor>{`${attrs.author_name}${
                attrs.publisher_name ? `｜${attrs.publisher_name}` : ''
              }`}</CardAuthor>
            )}
          </>
        ) : (
          <>
            <CardContentSkeleton />
            <CardContentSkeleton />
            <CardContentSkeleton />
          </>
        )}

        {children}
      </CardBody>
    </Container>
  )
}
