export const getSearchParams = (queryString?: string) => {
  try {
    return new URLSearchParams(
      decodeURIComponent(
        encodeURIComponent(queryString || window.location.search)
      )
    )
  } catch {
    return
  }
}
