import styled from '@emotion/styled'

const ErrorMessage = styled.p`
  margin-top: 0;
  margin-bottom: 10px;

  color: #f06449;
  font-size: 12px;
`

export default ErrorMessage
