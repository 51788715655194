import { LogoJsonLd, NextSeo, SocialProfileJsonLd } from 'next-seo'

import { isProd } from '@/libs/env'
import defaultConfig from '@/libs/seo/default-config'

const AppSeo = () => {
  return (
    <>
      <NextSeo {...defaultConfig.seoConfig} nofollow={!isProd} noindex={!isProd} />
      <LogoJsonLd {...defaultConfig.logoJsonLdConfig} />
      <SocialProfileJsonLd {...defaultConfig.socialProfileJsonLdConfig} />
    </>
  )
}

export default AppSeo
