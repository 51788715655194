import React from 'react'
import { Text } from '../../text'

type CountdownDescPropsType = React.PropsWithChildren<{
  countdownSrc?: string
  countdownTitle?: React.ReactNode
}>

const CountdownDesc = ({
  countdownSrc = 'https://public.wordup.com.tw/shop/assets/animation_timer.gif',
  countdownTitle,
  children,
}: CountdownDescPropsType) => {
  return (
    <>
      {countdownTitle && (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
          <img alt="countdown" width={20} height={20} src={countdownSrc} />
          <Text variant="label_s_500" color="inherit">
            {countdownTitle}
          </Text>
        </div>
      )}
      {children}
    </>
  )
}

export { CountdownDesc }
