import { useTranslation } from 'next-i18next'
import dynamic from 'next/dynamic'

import PackageInfo from '@/../package.json'
import { useIntercomHider } from '@libs-components/hooks'
import { shopPaths, withFullShopsUrl } from '@/libs/routes'
import { Stage } from '@/types/env-stage'
import { LangType } from '@/types/i18n'
import useAuth from './hooks/use-auth'

const Modal = dynamic(() => import('@/components/modals'), { ssr: false })
const WuAuthForm = dynamic(
  () => import('@libs-components/components/auth-form').then(e => e.AuthForm),
  { ssr: false },
)

const { version } = PackageInfo

const AuthModal = () => {
  const { i18n } = useTranslation()

  const {
    authLayout,
    authModalIsOpen,
    closeAuthModal,
    setAuthLayout,
    onError,
    onRequest,
    onSuccess,
    onForgetPasswordSuccess,
    onForgetPasswordError,
  } = useAuth()

  useIntercomHider({ shouldHideIntercom: authModalIsOpen })

  if (!authModalIsOpen) return null

  return (
    <Modal open={authModalIsOpen} onClose={closeAuthModal} maxWidth='375px' padding='1rem 1.25rem'>
      <WuAuthForm
        lang={i18n.resolvedLanguage as LangType}
        stage={process.env.NEXT_PUBLIC_STAGE as Stage}
        apiDomain={process.env.NEXT_PUBLIC_API_DOMAIN as string}
        clientInfo={`shop/${version}`}
        layout={authLayout}
        setLayout={setAuthLayout}
        domain={process.env.NEXT_PUBLIC_WU_SUB_DOMAIN as string}
        googleClientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        fbAppId={process.env.NEXT_PUBLIC_FACEBOOK_APP_ID}
        onRequest={onRequest}
        onSuccess={onSuccess}
        onError={onError}
        appleRedirectUri={process.env.NEXT_PUBLIC_SELF_DOMAIN}
        termUrl={withFullShopsUrl(shopPaths.TERMS_OF_SERVICE)}
        forgetPasswordLink={`${process.env.NEXT_PUBLIC_FLASHCARD_WEB_DOMAIN}/auth/reset-password`}
        onforgetPasswordSuccess={onForgetPasswordSuccess}
        onforgetPasswordError={onForgetPasswordError}
      />
    </Modal>
  )
}

export default AuthModal
