import styled from '@emotion/styled'
import React from 'react'

import themeVar from '../../theme/themeVar'

export interface TestimonyProps {
  onClick?: () => void
  recommenderName: string
  recommenderTitles?: string[]
  shortTestimonial: string
  smallImageUrl: string
}

export const ShortTestimony = (props: TestimonyProps) => {
  const {
    onClick,
    recommenderName,
    recommenderTitles,
    shortTestimonial,
    smallImageUrl,
  } = props

  return (
    <MinimizedContainer onClick={onClick} type="button">
      <PicContainer>
        <TestimonialImage
          src={smallImageUrl}
          alt={`${recommenderName}`}
          loading="lazy"
          decoding="async"
        />
      </PicContainer>
      <TextContainer>
        <NameContainer>
          {recommenderName}{' '}
          {recommenderTitles &&
            recommenderTitles.length > 0 &&
            `| ${recommenderTitles[0]}`}
        </NameContainer>
        <ShortTestimonyContainer
          dangerouslySetInnerHTML={{ __html: shortTestimonial }}
        />
      </TextContainer>
    </MinimizedContainer>
  )
}

const TestimonialImage = styled.img`
  width: auto;
  height: 100%;
  aspect-ratio: 105/111;
  object-fit: cover;
`

const MinimizedContainer = styled.button`
  border: none;
  padding: 0;
  max-height: 6.938rem;
  height: 6.938rem;
  display: flex;
  align-items: center;
  background-color: ${themeVar.color.palette['grayscale-100']};
  width: 100%;
  max-width: 20.063rem;
  cursor: pointer;
`

const PicContainer = styled.div`
  background-color: ${themeVar.color.palette['grayscale-100']};
  height: 100%;
  clip-path: polygon(0 0, 76% 0, 100% 100%, 0% 100%);
  min-width: 105px;
  max-width: 105px;
`

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 0.5rem 0.75rem 0.5rem 0;
  gap: 0.5rem;
  text-align: left;
  justify-content: center;
`

const NameContainer = styled.div`
  font-size: ${themeVar.fontSize.sm};
  font-weight: bold;
  color: ${themeVar.color.palette['grayscale-800']};
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
`

const ShortTestimonyContainer = styled.div`
  font-size: ${themeVar.fontSize.sm};
  color: ${themeVar.color.palette['grayscale-800']};
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  word-break: break-word;
  -webkit-box-orient: vertical;
  line-height: 1.5;
  letter-spacing: 1.17px;
  white-space: pre-line;
`
