import { LogoJsonLdProps, NextSeoProps, SocialProfileJsonLdProps } from 'next-seo'

const seoConfig: NextSeoProps = {
  title: '聰明學習 | WORD UP',
  description:
    '各類英檢考試的 app 教材一應俱全，教材均來自各大出版社，品質有保障！拿出手機馬上背單字、練題目，搭配 AI 演算法幫你複習不熟單字，托福TOEFL、GRE、雅思IELTS、多益TOEIC、指考、學測 so easy！',
  openGraph: {
    url: `${process.env.NEXT_PUBLIC_SELF_DOMAIN}`,
    title: '聰明學習 | WORD UP',
    description:
      '各類英檢考試的 app 教材一應俱全，教材均來自各大出版社，品質有保障！拿出手機馬上背單字、練題目，搭配 AI 演算法幫你複習不熟單字，托福TOEFL、GRE、雅思IELTS、多益TOEIC、指考、學測 so easy！',
    images: [
      {
        url: 'https://s3-ap-northeast-1.amazonaws.com/wordup-production-about/og_img_new.jpg',
        alt: 'WORD UP Image',
      },
    ],
    site_name: '聰明學習 | WORD UP',
  },
  facebook: {
    appId: '407289366313573',
  },
}

const logoJsonLdConfig: LogoJsonLdProps = {
  logo: 'https://s3-ap-northeast-1.amazonaws.com/wordup-production-about/logo_horizontal.png',
  url: process.env.NEXT_PUBLIC_SELF_DOMAIN ?? '',
}

const socialProfileJsonLdConfig: SocialProfileJsonLdProps = {
  type: 'Organization',
  name: '聰明學習 | WORD UP',
  url: process.env.NEXT_PUBLIC_SELF_DOMAIN ?? '',
  sameAs: ['https://www.facebook.com/worduphelper/'],
}

export default { seoConfig, logoJsonLdConfig, socialProfileJsonLdConfig }
