import Script from 'next/script'

export const InitLineTag = () => (
  <>
    <Script
      id='load-line-tag-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
          (function(g,d,o){
            g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
            var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
            var s=d.createElement('script');s.async=1;
            s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
            var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
          })(window, document);
          _lt('init', {
            customerType: 'account',
            tagId: '${process.env.NEXT_PUBLIC_LINE_TAG_ID}'
          });
          _lt('send', 'pv', ['${process.env.NEXT_PUBLIC_LINE_TAG_ID}']);
        `,
      }}
    />
    <noscript>
      {/* eslint-disable-next-line @next/next/no-img-element */}
      <img
        alt=''
        className='hidden h-[1px] w-[1px]'
        src={`https://tr.line.me/tag.gif?c_t=lap&t_id=${process.env.NEXT_PUBLIC_LINE_TAG_ID}&e=pv&noscript=1`}
      />
    </noscript>
  </>
)
