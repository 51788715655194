import { isWuAppWebview as checkIsWuAppWebView } from '../../utils/webview'

export const HideForWuAppWebView = ({
  children,
}: {
  children: JSX.Element
}) => {
  const isWuAppWebView = checkIsWuAppWebView()

  if (isWuAppWebView) return null

  return children
}
