import Script from 'next/script'

export const InitJamesLineTag = () => (
  <>
    <Script
      id='load-james-line-tag-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
          (function(g,d,o){
            g._ltq=g._ltq||[];g._lt=g._lt||function(){g._ltq.push(arguments)};
            var h=location.protocol==='https:'?'https://d.line-scdn.net':'http://d.line-cdn.net';
            var s=d.createElement('script');s.async=1;
            s.src=o||h+'/n/line_tag/public/release/v1/lt.js';
            var t=d.getElementsByTagName('script')[0];t.parentNode.insertBefore(s,t);
          })(window, document);
          _lt('init', {
            customerType: 'account',
            tagId: '${process.env.NEXT_PUBLIC_JAMES_LINE_TAG_ID}'
          });
          _lt('send', 'pv', ['${process.env.NEXT_PUBLIC_JAMES_LINE_TAG_ID}']);
        `,
      }}
    />
  </>
)
