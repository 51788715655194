import Script from 'next/script'

export const InitDcardAds = () =>
  process.env.NEXT_PUBLIC_DCARD_ADS_ID ? (
    <Script
      id='load-dcard-ads-script'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
        !function(){
        var d=function d(a) {
          for(var e=arguments.length,n=new Array(e>1?e-1:0),i=1;i<e;i++)
          n[i-1]=arguments[i];
          d.queue.push([a].concat(n))
        };
        d.queue=[],window.dadk=window.dadk||d;
        var a=document.createElement("script");
        a.src="undefined"!=typeof URLSearchParams?"https://unpkg.com/@dcard/web-ad-tracking-sdk/build/dadk.iife.js":"https://unpkg.com/@dcard/web-ad-tracking-sdk/build/outdated-dadk.iife.js",
        document.head.appendChild(a),
        window.dadk("init", "${process.env.NEXT_PUBLIC_DCARD_ADS_ID}"), window.dadk("track", "PageView")}();
      `,
      }}
    />
  ) : null
