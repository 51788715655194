const writeTextToClipboard = async ({
  text,
  onSuccess,
  onFail,
}: {
  text: string
  onSuccess?: () => void
  onFail?: (err: unknown) => void
}): Promise<void> => {
  try {
    await navigator.clipboard.writeText(text)
    if (onSuccess) onSuccess()
  } catch (err) {
    try {
      const dummyTextArea = document.createElement('textarea')
      dummyTextArea.value = text
      document.body.appendChild(dummyTextArea)
      dummyTextArea.select()
      document.execCommand('copy')
      document.body.removeChild(dummyTextArea)
      if (onSuccess) onSuccess()
    } catch (err) {
      if (onFail) onFail(err)
    }
  }
}

export default writeTextToClipboard
