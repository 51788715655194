import { createLocalStorage } from '../local-storage'
import { createCookieStorage } from '../cookie-storage'
import {
  Stage,
  USER_RELATED_KEY,
  LONGEST_COOKIE_EXPIRARY_TIME,
} from '../constants'

interface CredentialStorage {
  get: () => any
  save: (cred: object) => void
  update: (res: any) => void
  clear: (cb?: any) => void
}

const createInstance = (stage: Stage, domain: string): CredentialStorage => {
  const CookieStorage = createCookieStorage(stage)
  const LocalStorage = createLocalStorage(stage)

  const setCredential = (cred: object) => {
    CookieStorage.set({
      key: USER_RELATED_KEY.CREDENTIAL,
      value: JSON.stringify(cred),
      expires: LONGEST_COOKIE_EXPIRARY_TIME,
      domain,
    })
  }

  return {
    get: () => CookieStorage.get(USER_RELATED_KEY.CREDENTIAL, true) || {},
    save: setCredential,
    update: (res) => {
      if (res.headers instanceof Headers) {
        return setCredential({
          'access-token': res.headers.get('access-token'),
          client: res.headers.get('client'),
          uid: res.headers.get('uid'),
        })
      }
      if (res.headers['access-token']) {
        setCredential({
          'access-token': res.headers['access-token'],
          client: res.headers.client,
          uid: res.headers.uid,
        })
      }
    },
    clear: (cb) => {
      LocalStorage.clearAll()
      for (const key of Object.values(USER_RELATED_KEY)) {
        CookieStorage.del(key, {
          domain,
          expires: LONGEST_COOKIE_EXPIRARY_TIME,
        })
      }

      if (typeof cb === 'function') cb()
    },
  }
}

export default createInstance
