import { AuthType } from './types'
import { useForm } from 'react-hook-form'

import themeVar from '../../theme/themeVar'
import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import EmailInput from './email-input'
import { ForgetPasswordFormData } from './index'
import { useLocale } from './local-provider'
import PageDescription from './page-description'
import FormTitle from './title'

type Props = {
  onSubmit: (data: ForgetPasswordFormData) => void
  setLayout: (mode: AuthType) => void
}

const defaultValues = {
  email: '',
  password: '',
}

const FogetPasswordLayout = (props: Props) => {
  const { setLayout, onSubmit } = props
  const { forgetPassword } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues })

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      style={{ display: 'flex', flexDirection: 'column', rowGap: '1.25rem' }}
    >
      <FormTitle layout="forget-password" />

      <PageDescription text={forgetPassword.pageDesc} />

      <FormControl isInvalid={Boolean(errors.email)} mb="0px">
        <EmailInput register={register} />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <Button type="submit" disabled={isSubmitting}>
        {forgetPassword.sendEmail}
      </Button>

      <button
        type="button"
        onClick={() => setLayout('sign-in')}
        style={{
          border: 'none',
          outline: 'none',
          background: 'none',
          padding: 0,
          margin: 'auto',
          color: themeVar.color.palette['primary-500'],
          cursor: 'pointer',
        }}
      >
        {forgetPassword.toSignIn}
      </button>
    </form>
  )
}

export default FogetPasswordLayout
