import styled from '@emotion/styled'

export const Container = styled.div<{ isSelected: boolean }>`
  color: #383b3d;
  border: 1px solid #f2f2f2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  border-radius: 4px;

  p {
    margin: 0px;
  }

  .start {
    order: -1;
    margin-right: 8px;
  }

  .end {
    order: 9999;
    margin-left: 8px;
  }

  .sale-info {
    order: 1;
    display: flex;
    flex-direction: column;
    background-color: transparent;
    box-shadow: none;
    border: none;

    &:disabled {
      pointer-events: none;
    }

    .name {
      order: -1;
    }

    .price {
      order: 1;
      font-size: 18px;
      font-weight: bold;
      line-height: 1.8em;
      color: ${({ isSelected }) => (isSelected ? '#f06449' : '#383b3d')};

      @media (min-width: 600px) {
        order: 2;
      }
    }

    .detail {
      order: 2;

      @media (min-width: 600px) {
        order: 1;
      }
    }
  }
`
