import { type TFunction } from 'next-i18next'
import { utils as authUtils } from '@/features/auth'
import { isAppWebview } from '@libs-components/utils/device'
import { isProd } from '@/libs/env'
import { getAnonymousId } from '@/features/tracking/utils'
import { sendGtagEvent } from '@/features/tracking/gtag'
import { sendDcardAdsPageViewEvent } from '@/features/tracking/dcard'

const sendPageViewEvent = (t: TFunction) => {
  if (t(`crowdin:shop-enable-gtag-page-view-event${isProd ? '' : '-staging'}`) === 'true') {
    sendGtagEvent({ name: 'page_view', params: { page_location: window.location.href } })
  }
  window.fbq('trackSingle', process.env.NEXT_PUBLIC_FB_PIXEL_ID, 'PageView', {
    wu_anon_id: getAnonymousId(),
    external_id: authUtils.getUserId(),
    is_app: isAppWebview(),
  })
  sendDcardAdsPageViewEvent({ payload: { url: window.location.href } })
}

export default sendPageViewEvent
