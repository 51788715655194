import store from 'store' // we use store library directly to avoid circular dependency
import { KEY_CREDENTIAL, KEY_PLATFORM } from './constants'

export { remove } from 'store'

export const getLocalStorageCreds = () => store.get(KEY_CREDENTIAL) || {}

export const removeLocalStorageCreds = () => store.remove(KEY_CREDENTIAL)

export const getLocalStoragePlatformInfo = () => store.get(KEY_PLATFORM)
