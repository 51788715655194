import styled from '@emotion/styled'

const Input = styled.input`
  box-sizing: border-box;
  border: 1px solid #e5e5e5;
  border-radius: 8px;
  padding: 0.625rem;
  width: 100%;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: rgba(56, 59, 61, 0.6);
    font-size: 0.875rem;
  }
  :-ms-input-placeholder {
    color: rgba(56, 59, 61, 0.6);
    font-size: 0.875rem;
  }
`

export default Input
