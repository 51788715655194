import { ResetPasswordFormData } from '.'
import { useForm } from 'react-hook-form'
import { Text } from '../text'
import { ErrorMessage } from '../atoms'
import Button from '../atoms/button'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import FormTitle from './title'
import { useLocale } from './local-provider'

const defaultValues = {
  password: '',
  confirmPassword: '',
}

type PropTypes = {
  onSubmit: (data: ResetPasswordFormData) => void
}

const ResetPasswordForm = ({ onSubmit }: PropTypes) => {
  const { resetPwd } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    setError,
    handleSubmit,
  } = useForm({ defaultValues })

  const handleOnSubmit = async (data: ResetPasswordFormData) => {
    const { password, confirmPassword } = data
    if (password !== confirmPassword) {
      return setError('confirmPassword', { message: resetPwd.err.pwdNotMatch })
    } else {
      onSubmit(data)
    }
  }

  return (
    <form onSubmit={handleSubmit(handleOnSubmit)}>
      <FormTitle layout="reset-password" />

      <FormControl isInvalid={Boolean(errors.password)}>
        <Text variant="body_m_400" style={{ marginBottom: '0.25rem' }}>
          {resetPwd.newPwd.title}
        </Text>
        <Input
          type="password"
          placeholder={resetPwd.newPwd.plhdr}
          {...register('password', {
            required: resetPwd.err.noPwd,
            minLength: {
              value: 6,
              message: resetPwd.err.pwdTooShort || '',
            },
          })}
        />
      </FormControl>
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}

      <FormControl isInvalid={Boolean(errors.confirmPassword)}>
        <Text variant="body_m_400" style={{ marginBottom: '0.25rem' }}>
          {resetPwd.confirmNewPwd.title}
        </Text>
        <Input
          type="password"
          placeholder={resetPwd.confirmNewPwd.plhdr}
          {...register('confirmPassword', {
            required: resetPwd.err.noPwd,
            minLength: {
              value: 6,
              message: resetPwd.err.pwdTooShort || '',
            },
          })}
        />
      </FormControl>
      {errors.confirmPassword && (
        <ErrorMessage>{errors.confirmPassword.message}</ErrorMessage>
      )}

      <Button
        type="submit"
        disabled={isSubmitting}
        style={{ fontSize: '0.75rem', borderRadius: '9999px' }}
      >
        {isSubmitting ? resetPwd.loading : resetPwd.savePwd}
      </Button>
    </form>
  )
}

export default ResetPasswordForm
