import apiCaller from '@/apis/utils/caller'
import { getAnonymousId } from './utils'
import { utils as authUtils } from '@/features/auth'
import { sendLinkUser, sendTrafficTracking } from '@/apis/shop'

const trafficTracking = (params: {
  productId: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmContent?: string
  utmAdSet?: string
}) => {
  return apiCaller(
    sendTrafficTracking({
      wuAnonId: getAnonymousId() as string,
      userId: authUtils.getUserId() as string,
      ...params,
    }),
  )
}

const linkUser = async (userId: string) => {
  return apiCaller(sendLinkUser({ wuAnonId: getAnonymousId() as string, userId }))
}

export default { trafficTracking, linkUser }
