import { SignInFormData, SignUpFormData } from '.'
import { useForm } from 'react-hook-form'
import { Text } from '../text'
import themeVar from '../../theme/themeVar'
import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import EmailInput from './email-input'
import { useLocale } from './local-provider'

const defaultValues = {
  name: '',
  email: '',
  password: '',
}

type PropTypes = {
  termUrl?: string
  handleAuth: (data: SignInFormData | SignUpFormData) => void
}

const SignUpForm = ({ termUrl, handleAuth }: PropTypes) => {
  const { name, password, signup, error, loading, privacy } = useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues })

  return (
    <form onSubmit={handleSubmit(handleAuth)}>
      <FormControl isInvalid={Boolean(errors.name)}>
        <Input
          placeholder={name.plhr}
          {...register('name', { required: error.required })}
        />
      </FormControl>
      {errors.name && <ErrorMessage>{errors.name.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.email)}>
        <EmailInput register={register} />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)} mb="1.25rem">
        <Input
          placeholder={password.plhr}
          type="password"
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
      </FormControl>
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}

      <Button
        type="submit"
        disabled={isSubmitting}
        style={{ marginBottom: '0.25rem' }}
      >
        {isSubmitting ? loading : signup.submit}
      </Button>

      {termUrl && (
        <Text component="p" variant="label_s_400">
          {privacy.message}{' '}
          <a
            href={termUrl}
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: themeVar.color.palette['primary-500'] }}
          >
            {privacy?.linkText}
          </a>
        </Text>
      )}
    </form>
  )
}

export default SignUpForm
