import LinearProgress, {
  linearProgressClasses,
  LinearProgressProps,
} from '@mui/material/LinearProgress'

import themeVar from '../../theme/themeVar'
import React from 'react'

const COLORS = themeVar.color.palette

type ProgressbarPropTypes = LinearProgressProps & {
  percentage: number
  bgColor?: keyof typeof COLORS
}

const Progressbar = (props: ProgressbarPropTypes) => {
  const { percentage, bgColor, ...restProps } = props

  return (
    <div
      style={{
        display: 'flex',
        fontSize: '0.875rem',
        fontWeight: 'bold',
        justifyContent: 'space-between',
      }}
    >
      <LinearProgress
        value={percentage > 100 ? 100 : percentage}
        variant="determinate"
        sx={{
          width: '100%',
          borderRadius: '0.5rem',
          height: '10px;',
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: COLORS['grayscale-200'],
          },
          [`& .${linearProgressClasses.bar}`]: {
            backgroundColor: COLORS[bgColor || 'peach-500'],
          },
        }}
        {...restProps}
      />
    </div>
  )
}

export default Progressbar
