import { AuthType } from './types'
import { useMemo, CSSProperties } from 'react'
import themeVar from '../../theme/themeVar'
import { Text } from '../text'
import { useLocale } from './local-provider'

type TitleProps = {
  layout: AuthType
  setLayout?: (layout: AuthType) => void
}

const FormTitle = ({ layout, setLayout }: TitleProps) => {
  const { signin, signup, forgetPassword, resetPwd } = useLocale()
  const text = useMemo(() => {
    switch (layout) {
      case 'sign-in':
        return signin.title
      case 'sign-up':
        return signup.title
      case 'forget-password':
        return forgetPassword.title
      case 'reset-password':
        return resetPwd.title
      default:
        return signin.title
    }
  }, [layout, signin.title, signup.title, forgetPassword.title, resetPwd.title])
  const style: CSSProperties = useMemo(() => {
    const defaultStyles: CSSProperties = {
      display: 'flex',
      flexDirection: 'column',
    }
    switch (layout) {
      case 'reset-password': {
        return {
          ...defaultStyles,
          textAlign: 'center',
          marginBottom: '1.25rem',
        }
      }
      default:
        return defaultStyles
    }
  }, [layout])

  return (
    <div style={style}>
      <Text component="h5" variant="heading_m_500">
        {text}
      </Text>
      {setLayout && (
        <div>
          <Text variant="body_s_400" style={{ marginRight: '0.25rem' }}>
            {layout === 'sign-in' ? signin?.hintTxt : signup?.hintTxt}
          </Text>
          <button
            type="button"
            style={{
              border: 'none',
              outline: 'none',
              cursor: 'pointer',
              background: 'none',
              color: themeVar.color.palette['primary-500'],
            }}
            onClick={() =>
              setLayout(layout === 'sign-in' ? 'sign-up' : 'sign-in')
            }
          >
            {layout === 'sign-in' ? signin?.hintBtn : signup?.hintBtn}
          </button>
        </div>
      )}
    </div>
  )
}

export default FormTitle
