interface HeadersProps {
  lang?: string
  clientInfo?: string
  endUserClientInfo?: string
  credentials?: {
    'access-token': string
    client: string
    uid: string
  }
}

export const requestHeaders = ({
  lang,
  clientInfo,
  endUserClientInfo,
  credentials,
}: HeadersProps) => {
  return new Headers({
    'Content-Type': 'application/json',
    'Client-Lang': lang || 'zh-TW',
    'Client-Info': clientInfo || 'N/A',
    'End-User-Client-Info': endUserClientInfo || 'N/A',
    ...credentials,
  })
}
