import type { Web2UnusedRedeemCodes } from './dataType'
import type { LineItemInfoProps } from '../order-info/line-item-info'
import React from 'react'

import { LineItemInfo } from '../order-info/line-item-info'

import transformer from './transformer'

export type MaterialsWithRedeemCodesProps = {
  locale: string
  orderPaid: boolean
  redeemCodes: Web2UnusedRedeemCodes
  renderProductImage: (imageUrl: string) => React.ReactNode
  handleRedeemCodeClick: LineItemInfoProps['handleRedeemCodeButtonClick']
  t: any
}

export const MaterialsWithRedeemCodes = ({
  locale,
  orderPaid,
  redeemCodes,
  renderProductImage,
  handleRedeemCodeClick,
  t,
}: MaterialsWithRedeemCodesProps) => {
  const materialsWithRedeemCodes = transformer(redeemCodes)

  return (
    <ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
      {materialsWithRedeemCodes?.map((material, index) => {
        const { materialId, imageUrl, productTitle, codes } = material
        const isLast = index === materialsWithRedeemCodes.length - 1

        return (
          <li
            key={materialId}
            style={{
              display: 'flex',
              flexDirection: 'column',
              fontSize: 12,
              borderBottom: '1px solid rgb(238,239,239)', // Grayscale/100
              paddingBottom: 16,
              marginBottom: 16,
              ...(isLast && { border: 'none' }),
            }}
          >
            <LineItemInfo
              key={materialId}
              locale={locale}
              redeemCodes={codes}
              orderPaid={orderPaid}
              productTitle={productTitle}
              totalPrice=""
              variantKey="redeem_code"
              renderProductImage={() => renderProductImage(imageUrl)}
              handleRedeemCodeButtonClick={handleRedeemCodeClick}
              t={t}
            />
          </li>
        )
      })}
    </ul>
  )
}
