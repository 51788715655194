import * as React from 'react'

interface TocLocale {
  toc: {
    all_units: string
    trial_units: string
    expiration_date: string
    publisher: string
    material_unit: string
    preview: string
    extra_previewable_info: string
  }
}

export interface Locales {
  en: TocLocale
  'zh-TW': TocLocale
}

const defaultLocales: Locales = {
  en: {
    toc: {
      all_units: 'All units',
      trial_units: 'Trial units',
      expiration_date: 'Expiration date',
      publisher: 'Publisher',
      material_unit: 'unit(s)',
      preview: 'Preview',
      extra_previewable_info: 'Additional previewable info',
    },
  },
  'zh-TW': {
    toc: {
      all_units: '所有單元',
      trial_units: '試閱',
      expiration_date: '使用期限',
      publisher: '出版社',
      material_unit: '單元',
      preview: '試閱',
      extra_previewable_info: '教材相關資訊(不在教材內的額外試閱)',
    },
  },
}

export const LocalContext = React.createContext(defaultLocales['zh-TW'])

type PropTypes = React.PropsWithChildren<{
  defaultLang: 'zh-TW' | 'en'
  locales?: typeof defaultLocales
}>

export const TocLocalProvider: React.FC<PropTypes> = ({
  defaultLang,
  locales = defaultLocales,
  children,
}) => {
  const [lang, setLang] = React.useState(defaultLang)

  React.useEffect(() => {
    setLang(defaultLang)
  }, [defaultLang])

  return (
    <LocalContext.Provider value={locales[lang]}>
      {children}
    </LocalContext.Provider>
  )
}

export const useTocLocale = () => React.useContext(LocalContext)
