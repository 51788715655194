import type { ErrorCodeType } from './error-codes'
import type { AuthType, LoginTypes } from '@libs-components/index'
import { atom, useAtom } from 'jotai'
import { useTranslation } from 'next-i18next'
import { useCallback, useEffect } from 'react'

import { getIntercomUserId } from '@/components/intercom/utils'
import toast from '@/components/toast'
import Swal from '@/components/sweetalert'
import { handleWuAppSignInSuccess } from '@/features/auth/post-sign-in-success'
import { getAnonymousId } from '@/features/tracking'
import useGlobalLoader from '@/hooks/use-global-loader'
import { useWuIntercom, useIntercomHider } from '@libs-components/hooks'
import { isWuAppWebview } from '@libs-components/utils/device'
import { notify, setUser as setSentryUser } from '@/libs/error-notifier'
import refreshGtagUserData from '@/features/tracking/gtag/refreshGtagUserData'
import { signOut } from '../../../apis/auth'
import authUtils from '../utils'

type AuthModalParamsType<T = unknown> = {
  layout: AuthType
  onSuccess?: (() => T | Promise<T>) | null
}

const authAtom = atom(false)
const authModalIsOpenAtom = atom(false)
const authLayoutAtom = atom<AuthModalParamsType['layout']>('sign-in')
const onSuccessAtom = atom<AuthModalParamsType['onSuccess']>(null)

const defaultUserInfo = { email: '', name: '', userId: '' }
const userInfoAtom = atom(defaultUserInfo)

const useAuth = () => {
  const [isLoggedIn, setIsLoggedIn] = useAtom(authAtom)
  const [authModalIsOpen, setAuthModalIsOpen] = useAtom(authModalIsOpenAtom)
  const [authLayout, setAuthLayout] = useAtom(authLayoutAtom)
  const [onLoginSuccess, setOnLoginSuccess] = useAtom(onSuccessAtom)
  const [userInfo, setUserInfo] = useAtom(userInfoAtom)

  const { rebootIntercom } = useWuIntercom()
  useIntercomHider({
    shouldHideIntercom: authModalIsOpen,
  })

  const { startLoading, stopLoading } = useGlobalLoader()

  const { t } = useTranslation()

  const deleteCredential = useCallback(() => {
    authUtils.clearCookies()
  }, [])

  const logout = useCallback(async () => {
    const wuAnonId = getAnonymousId()
    try {
      await signOut()
    } finally {
      setIsLoggedIn(false)
      setUserInfo(defaultUserInfo)
      deleteCredential()
      rebootIntercom({
        userId: getIntercomUserId(String(wuAnonId)) ?? '',
      })
      refreshGtagUserData()
    }
  }, [setIsLoggedIn, setUserInfo, deleteCredential, rebootIntercom])

  const onFinish = useCallback(() => {
    stopLoading()
  }, [stopLoading])

  const onRequest = useCallback(() => {
    startLoading()
  }, [startLoading])

  const onError = useCallback(
    ({
      type,
      res,
      status,
      errCode,
      msg,
    }: {
      type: LoginTypes
      errCode?: ErrorCodeType | undefined
      msg?: string | undefined
      res?: unknown
      status?: number | undefined
    }) => {
      if (type === 'email') {
        Swal.fire({
          icon: 'warning',
          title: '',
          text: msg,
        })

        const expectedErrorCodes: ErrorCodeType[] = [
          'invalid_login_credentials',
          'email_already_exists',
        ]

        if (errCode && !expectedErrorCodes.includes(errCode)) {
          notify({
            err: new Error('Email sign-in backend error'),
            context: {
              key: 'Email sign-in or sign-up failed.',
              type,
              status,
              body: JSON.stringify(res),
              msg,
            },
          })
        }
      } else if (type === 'fb') {
        Swal.fire({
          title: t('auth.fb.err.title') as string,
          icon: 'warning',
          text: t('auth.fb.err.msg') as string,
          confirmButtonText: t('common.ok') as string,
        })
      } else if (type === 'google') {
        if (errCode === 'idpiframe_initialization_failed') return

        Swal.fire({
          icon: 'warning',
          title: t('auth.google.err.title') as string,
          text: t('auth.google.err.msg') as string,
          confirmButtonText: t('common.ok') as string,
        })

        notify({
          err: new Error('social login google error'),
          context: {
            msg,
            type,
            errCode,
          },
        })
      } else if (type === 'apple') {
        if (errCode === 'popup_closed_by_user') return
      }

      onFinish()
    },
    [t, onFinish],
  )

  const onForgetPasswordSuccess = useCallback((message: string) => {
    toast.success({ message })
  }, [])

  const onForgetPasswordError = useCallback(
    ({ res }: { res?: unknown; status?: number | undefined }) => {
      toast.error({
        message: (res as any)?.errors?.[0]?.detail ?? t('err.something_went_wrong'),
      })
    },
    [t],
  )

  const closeAuthModal = useCallback(() => {
    setAuthModalIsOpen(false)
  }, [setAuthModalIsOpen])

  const handleWuUserBinding = useCallback(async (userId: string) => {
    import('@/features/tracking/wu').then(({ default: wu }) => {
      wu.linkUser(userId).catch(err => {
        notify({ err, context: { key: 'Auth link user failed' } })
      })
    })
  }, [])

  const openAuthModal = useCallback(
    ({ layout, onSuccess }: AuthModalParamsType) => {
      setAuthLayout(layout)
      setAuthModalIsOpen(true)

      if (typeof onSuccess === 'function') {
        setOnLoginSuccess(() => onSuccess)
      }
    },
    [setAuthLayout, setAuthModalIsOpen, setOnLoginSuccess],
  )

  const onSuccess = useCallback(
    (res: any) => {
      const { data, type } = res
      const {
        data: { id, email, name },
      } = data

      authUtils.saveUserId(id)
      setIsLoggedIn(true)
      setUserInfo({ email, name, userId: id })
      setSentryUser({ id, name, email })
      closeAuthModal()
      handleWuUserBinding(id)

      if (isWuAppWebview()) {
        handleWuAppSignInSuccess()
      }

      refreshGtagUserData()
      import('@/features/tracking/gtag').then(gtag => {
        gtag.default.internal.sendEvent({
          name: type === 'sign-up' ? 'sign_up' : 'sign_in',
          params: { user_id: id },
        })
      })
      if (type === 'sign-up') {
        import('@/features/tracking/pixel').then(fbp => {
          fbp.default.internal.trackSingle('CompleteRegistration', {
            status: true,
            currency: 'TWD',
          })
        })
        import('@/features/tracking/dcard').then(({ sendDcardAdsCompleteRegistrationEvent }) => {
          sendDcardAdsCompleteRegistrationEvent({
            payload: { contentName: window.document.title, status: true },
          })
        })
      }

      rebootIntercom({
        userId: getIntercomUserId(String(data.data.id)),
        name: data.data.name,
      })

      if (typeof onLoginSuccess === 'function') {
        onLoginSuccess()
      }

      onFinish()
    },
    [onLoginSuccess, closeAuthModal, handleWuUserBinding, onFinish, setIsLoggedIn, setUserInfo],
  )

  // If the user is logged in,
  // it invokes the callback and returns its result.
  // If the user is not logged in,
  // it opens the authentication modal (default is sign-in modal)
  const withAuthCheck = useCallback(
    <T>(authModalParams?: Partial<AuthModalParamsType<T>>) => {
      if (isLoggedIn) {
        return authModalParams?.onSuccess?.()
      }
      openAuthModal({ layout: 'sign-in', ...authModalParams })
    },
    [isLoggedIn, openAuthModal],
  )

  useEffect(() => {
    const cred = authUtils.getCredential()

    const hasCredential = Boolean((cred as any)?.uid)
    setIsLoggedIn(hasCredential)

    const userId = authUtils.getUserId() as string
    if (hasCredential) {
      setUserInfo(prev => ({ ...prev, email: (cred as any).uid, userId }))
    }

    import('@/features/tracking/gtag').then(gtag => {
      gtag.default.setUser(userId)
    })
    setSentryUser({ id: userId, email: (cred as any)?.uid })
  }, [setIsLoggedIn, isLoggedIn, setUserInfo])

  return {
    userInfo,
    isLoggedIn,
    logout,
    authModalIsOpen,
    openAuthModal,
    closeAuthModal,
    authLayout,
    onError,
    setAuthLayout,
    onRequest,
    onSuccess,
    onForgetPasswordSuccess,
    onForgetPasswordError,
    getUserId: authUtils.getUserId,
    withAuthCheck,
  }
}

export default useAuth
