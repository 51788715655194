import styled from '@emotion/styled'
import * as React from 'react'

const Container = styled.section`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f2f2f2;

  .title {
    letter-spacing: 1.75;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0px;
    color: #383b3d;

    li {
      p {
        margin: 0;
      }

      .item__title {
        letter-spacing: 1px;
        margin-top: 16px;
      }

      .item__description {
        padding-left: 20px;
        padding-top: 4px;
      }
    }
  }
`

export interface SuitableForItem {
  title?: string
  description?: string
}

export interface SuitableForProps {
  title?: string
  data: SuitableForItem[]
}

const symbols = ['❶', '❷', '❸', '❹', '❺', '❻', '❼', '❽', '❾']

export const SuitableFor: React.FC<SuitableForProps> = ({ title, data }) => {
  return (
    <Container>
      {title && <div className="title">{title}</div>}
      <ul>
        {data.map(({ title, description }, idx) => (
          <li key={title}>
            <p className="item__title">
              {symbols[idx]} {title}
            </p>
            <p className="item__description">{description}</p>
          </li>
        ))}
      </ul>
    </Container>
  )
}
