import _Swal, { SweetAlertIcon, SweetAlertResult } from 'sweetalert2'
import withReactContent, { ReactSweetAlertOptions } from 'sweetalert2-react-content'
import { Icon } from '@libs-components/components/icon'

import TailwindConfig from 'tailwind.config'

const TailwindColor = TailwindConfig.theme.extend.colors

type TailwindColorTypes = keyof typeof TailwindColor

const sweetalert = withReactContent(_Swal)

interface CustomizedReactSweetAlertOptions extends ReactSweetAlertOptions {
  cancelButtonColor?: TailwindColorTypes
  confirmButtonColor?: TailwindColorTypes
}
const Swal = {
  ...sweetalert,
  close: sweetalert.close,
  fire: async (options: CustomizedReactSweetAlertOptions): Promise<SweetAlertResult> => {
    return sweetalert.fire({
      ...options,
      cancelButtonColor: options.cancelButtonColor
        ? TailwindColor[options.cancelButtonColor]
        : TailwindColor['grayscale-300'],
      confirmButtonColor: options.confirmButtonColor
        ? TailwindColor[options.confirmButtonColor]
        : TailwindColor['primary-500'],
      iconHtml: options.icon ? getCustomizedIcon(options.icon) : undefined,
      customClass:
        typeof options.customClass === 'string'
          ? options.customClass
          : Object.entries(options.customClass || {}).reduce(
              (acc, [key, extendClass]) => {
                if (acc[key] && extendClass) {
                  acc[key] = [
                    ...acc[key],
                    ...(typeof extendClass === 'string'
                      ? extendClass?.split(' ') || []
                      : extendClass),
                  ]
                }
                return acc
              },
              {
                ...options.customClass,
                icon: ['popup_confirmation_icon'],
                popup: ['popup_confirmation_popup'],
                container: ['popup_confirmation_container'],
                htmlContainer: ['popup_confirmation_html_container'],
                title: ['popup_confirmation_title'],
                actions: ['popup_confirmation_actions'],
                confirmButton: ['popup_confirmation_confirm_button'],
                cancelButton: ['popup_confirmation_cancel_button'],
              } as Record<string, string | string[]>,
            ),
    })
  },
}

export default Swal

const getCustomizedIcon = (icon: SweetAlertIcon) => {
  switch (icon) {
    case 'success': {
      return <Icon type='check_circle' style={{ color: TailwindColor['green-500'] }} size={60} />
    }
    case 'warning':
      return <Icon type='emergency_home' style={{ color: TailwindColor['yellow-500'] }} size={60} />
    case 'error':
      return <Icon type='dangerous' style={{ color: TailwindColor['peach-500'] }} size={60} />
    case 'info':
      return <Icon type='info' style={{ color: TailwindColor['primary-500'] }} size={60} />
    default:
      return undefined
  }
}
