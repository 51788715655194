export const appendSearchParamsToUrl = ({
  url,
  params,
  shouldReplaceDuplicateParam,
}: {
  url: string
  params: Record<string, string>
  shouldReplaceDuplicateParam?: boolean
}) => {
  const urlObj = new URL(url)

  const existingParams = new URLSearchParams(urlObj.searchParams)

  for (const [key, value] of Object.entries(params)) {
    if (shouldReplaceDuplicateParam) {
      existingParams.set(key, value)
    } else {
      if (!existingParams.has(key)) {
        existingParams.append(key, value)
      }
    }
  }

  const newUrl = new URL(`${urlObj.origin}${urlObj.pathname}?${existingParams}`)

  return newUrl.href
}

export const getQueryString = ({ obj = {} }: { obj: Record<string, any> }) => {
  const querystring = Object.entries(obj)
    .filter(([key, value]) => key && value?.length)
    .map(
      ([key, value]) =>
        `${key}=${Array.isArray(value) ? value.join(',') : value}`
    )
    .join('&')
  return querystring?.length ? `?${querystring}` : ''
}
