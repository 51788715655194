import { twMerge } from 'tailwind-merge'

type Props = React.ComponentProps<'button'>

const RESET_CLASS_NAME = ['border-none', 'outline-none', 'bg-transparent']
const RESET_PSEUDO_CLASS_NAME = ['focus', 'focus-visible', 'active'].flatMap(p =>
  RESET_CLASS_NAME.map(c => p + ':' + c),
)

const Button = ({ className, children, ...buttonProps }: Props) => {
  return (
    <button
      type='button'
      className={twMerge(
        RESET_CLASS_NAME,
        RESET_PSEUDO_CLASS_NAME,
        'text-grayscale-800 cursor-pointer appearance-none p-0',
        className,
      )}
      {...buttonProps}
    >
      {children}
    </button>
  )
}

export { Button }
