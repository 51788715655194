import React from 'react'
import { useTranslation } from 'react-i18next'

import styleVars from '../../theme/themeVar'
import { Icon } from '../icon'
import { Confirm, Cancel } from '../button'
import { WuModal } from './Base'

type ErrorModalProps = React.ComponentProps<typeof WuModal> & {
  text: string
  onCancel?: (e: React.MouseEvent<HTMLButtonElement>) => void
  onConfirm?: (e: React.MouseEvent<HTMLButtonElement>) => void
}

const ErrorModal = ({
  text,
  onCancel,
  onConfirm,
  ...props
}: ErrorModalProps) => {
  const { t } = useTranslation()

  return (
    <WuModal
      hideCloseButton
      padding="1.25rem 1.5rem"
      mainBlockStyles={{
        textAlign: 'center',
      }}
      {...props}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          gap: '0.75rem',
        }}
      >
        <header style={{ width: '100%' }}>
          <Icon
            type="dangerous"
            size={60}
            style={{
              color: styleVars.color.palette['peach-500'],
              margin: '0 auto',
            }}
          />
        </header>
        <p
          style={{
            margin: 0,
            padding: 0,
            fontSize: '1.25rem',
            fontWeight: 'bold',
            letterSpacing: '0.125rem',
            color: '#383b3d',
          }}
        >
          {text}
        </p>
        <footer style={{ width: '100%' }}>
          {onCancel && (
            <Cancel onClick={onCancel}>{t('crowdin:common.cancel')}</Cancel>
          )}
          {onConfirm && (
            <Confirm onClick={onConfirm}>{t('crowdin:common.confirm')}</Confirm>
          )}
        </footer>
      </div>
    </WuModal>
  )
}

export { ErrorModal, type ErrorModalProps }
