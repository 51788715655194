import { SignInFormData, SignUpFormData } from '.'
import { AuthType } from './types'
import { useForm } from 'react-hook-form'
import { Text } from '../text'
import Button from '../atoms/button'
import ErrorMessage from '../atoms/error-message'
import FormControl from '../atoms/form-control'
import Input from '../atoms/input'
import { useLocale } from './local-provider'

const defaultValues = {
  email: '',
  password: '',
}

type PropTypes = {
  handleAuth: (data: SignInFormData | SignUpFormData) => void
  setLayout: (mode: AuthType) => void
}

const SignInForm = ({ handleAuth, setLayout }: PropTypes) => {
  const { signin, error, loading, password, email, forgetPassword } =
    useLocale()
  const {
    register,
    formState: { errors, isSubmitting },
    handleSubmit,
  } = useForm({ defaultValues })

  return (
    <form onSubmit={handleSubmit(handleAuth)}>
      <FormControl isInvalid={Boolean(errors.email)}>
        <Input
          type="email"
          placeholder={email.plhr}
          {...register('email', {
            required: error.required,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: error.email || '',
            },
          })}
        />
      </FormControl>
      {errors.email && <ErrorMessage>{errors.email.message}</ErrorMessage>}

      <FormControl isInvalid={Boolean(errors.password)} mb="0.5rem">
        <Input
          type="password"
          placeholder={password.plhr}
          {...register('password', {
            required: error.required,
            minLength: { value: 6, message: error.password || '' },
          })}
        />
      </FormControl>
      {errors.password && (
        <ErrorMessage>{errors.password.message}</ErrorMessage>
      )}

      <button
        type="button"
        onClick={() => setLayout('forget-password')}
        style={{
          padding: 0,
          border: 'none',
          outline: 'none',
          background: 'none',
          cursor: 'pointer',
          marginBottom: '1rem',
        }}
      >
        <Text variant="body_s_400" color="grayscale-400">
          {forgetPassword.title}
        </Text>
      </button>

      <Button type="submit" disabled={isSubmitting}>
        {isSubmitting ? loading : signin.submit}
      </Button>
    </form>
  )
}

export default SignInForm
