import styled from '@emotion/styled'
import * as React from 'react'

const Container = styled.section`
  padding: 12px 16px;
  border-radius: 8px;
  background-color: #f2f2f2;

  div:not(:last-child) {
    margin-bottom: 12px;
  }
`

const PureTitle = styled.h3`
  font-size: 16px;
  font-weight: bold;
  line-height: 1.4;
  letter-spacing: 1.6px;

  &:not(:first-child) {
    margin-top: 16px;
  }
`

const IconTextContainer = styled.div`
  display: flex;
  align-items: center;
  font-size: 16px;
  line-height: 1.5;
`

export interface HighlightItem {
  type: string
  text: string
}

export interface HighlightsPropTypes {
  icon?: React.ReactNode
  className?: string
  highlights: HighlightItem[]
}

export const Highlights: React.FC<HighlightsPropTypes> = ({
  icon,
  className,
  highlights,
}) => {
  return (
    <Container className={className}>
      {highlights.map((highlight) => {
        const { type, text } = highlight

        if (type === 'header') {
          return (
            <div key={text}>
              <PureTitle>{text}</PureTitle>
            </div>
          )
        }
        return (
          <IconTextContainer key={text}>
            {icon && icon}
            <div>{text}</div>
          </IconTextContainer>
        )
      })}
    </Container>
  )
}
