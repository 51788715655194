import type { RedeemCode } from '.'

type RedeemCodesGroupByMaterialKindType = {
  [materialExpiryKindOrExactTime: string]: RedeemCode[]
}

const getExactTime = (locale: string, exactTime: string) => {
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
    timeZone: 'Asia/Taipei',
  }).format(new Date(exactTime))
}

export const processRedeemCodesToEntries = (locale: string, redeemCodes: RedeemCode[]) => {
  const temp: RedeemCodesGroupByMaterialKindType = {}

  redeemCodes.forEach(redeemCode => {
    if (redeemCode.materialExpiryKind) {
      switch (redeemCode.materialExpiryKind) {
        case 'na': {
          if (!temp[redeemCode.materialExpiryKind]) {
            temp[redeemCode.materialExpiryKind] = []
          }

          temp[redeemCode.materialExpiryKind].push(redeemCode)
          break
        }
        case 'period_after_enrolment': {
          const key = `${redeemCode.materialExpiryOptions.value}-${redeemCode.materialExpiryOptions.unit}`

          if (!temp[key]) temp[key] = []
          temp[key].push(redeemCode)
          break
        }
        case 'exact_time': {
          const exactTime = getExactTime(locale, redeemCode.materialExpiryOptions.endsAt)

          if (!temp[exactTime]) temp[exactTime] = []
          temp[exactTime].push(redeemCode)
          break
        }
        default:
          break
      }
    }
  })

  return Object.entries(temp)
}
