import styled from '@emotion/styled'
import themeVar from '../../theme/themeVar'

const Button = styled.button`
  cursor: pointer;
  border: none;
  outline: none;
  padding: 0.5rem;
  width: 100%;
  height: 2.5rem;
  border-radius: 9999px;
  color: ${themeVar.color.palette['grayscale-000']};
  background-color: ${themeVar.color.palette['primary-500']};

  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: ${themeVar.color.palette['grayscale-300']};
    background-color: ${themeVar.color.palette['grayscale-100']};
  }
`

export default Button
