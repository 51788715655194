import Script from 'next/script'
import { useEffect } from 'react'
import { useTranslation } from 'next-i18next'
import sendPageViewEvent from '@/features/tracking/events/send-page-view-event'

let isPixelInited = false

const InitPixel = () => {
  const { t } = useTranslation()

  useEffect(() => {
    if (!isPixelInited) {
      window.fbq('init', process.env.NEXT_PUBLIC_FB_PIXEL_ID)
      isPixelInited = true
      sendPageViewEvent(t)
    }
  }, [t])

  return (
    <Script
      id='init-pixel'
      strategy='afterInteractive'
      dangerouslySetInnerHTML={{
        __html: `
          !(function (f, b, e, v, n, t, s) {
            if (f.fbq) return;
            n = f.fbq = function () {
              n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
            };
            if (!f._fbq) f._fbq = n;
            n.push = n;
            n.loaded = !0;
            n.version = "2.0";
            n.queue = [];
            t = b.createElement(e);
            t.async = !0;
            t.src = v;
            s = b.getElementsByTagName(e)[0];
            s.parentNode.insertBefore(t, s);
          })(
            window,
            document,
            "script",
            "https://connect.facebook.net/en_US/fbevents.js"
          );
        `,
      }}
    />
  )
}

export default InitPixel
