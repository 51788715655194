import { atom, useAtom } from 'jotai'
import { useCallback } from 'react'

const isLoadingAtom = atom(false)

const useGlobalLoader = () => {
  const [isLoading, setIsLoading] = useAtom(isLoadingAtom)

  const startLoading = useCallback(() => {
    setIsLoading(true)
  }, [])

  const stopLoading = useCallback(() => {
    setIsLoading(false)
  }, [])
  return { isLoading, startLoading, stopLoading }
}

export default useGlobalLoader
