import { isAppWebview } from '../device'
import { getQueryString } from '../url'

export const PORTAL_PATH = {
  AUTH: '/auth',
  SIGN_UP: '/sign-up',
  SIGN_IN: '/sign-in',
  DASHBOARD: '/dashboard',
  HOME: '/',
  MATERIALS: '/materials',
  EXAM_PAPER: '/exam_paper',
  EXAM: '/exam',
  CHAPTER: '/chapter',
  COMPONENTS: '/components',
  INCORRECT_QUESTIONS: '/incorrect_questions',
  AI_PRACTICE: '/ai_practice',
  DECKS: '/decks',
  DECK_CARDS: '/cards',
  INTERESTS: '/interests',
  WUWOW: '/wuwow',
  AMC: '/amc',
  SCHOOL_TEACHERS: '/school_teachers',
  WUWOW_JR: '/wuwow?target=junior',
  CARDS_EDIT: '/cards/edit',

  ACCOUNT_SETTINGS: '/account/settings',
  ACCOUNT_ORDER_HISTORY: '/account/order-history',
  ACCOUNT_SUBSCRIPTION_ORDER_HISTORY: '/account/subscription-order-history',
  ACCOUNT_PREFERENCE: '/account/preference',
  ACCOUNT_REDEEM_CODE: '/account/redeem-code',
  ACCOUNT_CREDIT_CARD_MANAGEMENT: '/account/credit-card-management',
  ACCOUNT_CASHBACK_MISSIONS: '/account/cashback-missions',
  ACCOUNT_DELETE: '/account/delete',

  DASHBOARD_CLASSES: '/dashboard/classes',
  DASHBOARD_MATERIALS: '/dashboard/materials',
  DASHBOARD_SEATS: '/dashboard/seats',
  DASHBOARD_PRODUCTS: '/dashboard/products',
  DASHBOARD_SALES: '/dashboard/sales',
  DASHBOARD_SALES_SUMMARY: '/dashboard/sales/summary',
  DASHBOARD_COMMENTS: '/dashboard/comments',
  DASHBOARD_CREDIT_CARD_MANAGEMENT: '/dashboard/credit-card-management',
  DASHBOARD_CREDIT_PERSONAL_INFO_MANAGEMENT:
    '/dashboard/personal-info-management',
  DASHBOARD_ACCOUNTING_BANK_INFO: '/dashboard/accounting/bank-info',
  DASHBOARD_ORGS_MANAGEMENT: '/dashboard/orgs-management',
  DASHBOARD_MEMBERS_MANAGEMENT: '/dashboard/members-management',

  APP_DECKS: '/app/decks',
  CARDS: '/app/decks/:id/cards',
  CREATE_CARD: '/app/decks/:id/create/new',
  EDIT_CARD: '/app/decks/:id/cards/:id/edit',
  MOVE_CARDS: '/app/decks/:id/cards/move',
  QUERY_CARD: '/app/decks/:id/query/:word',
  REVIEW: '/app/decks/:id/review',
  REVIEW_TENSE: '/app/decks/:id/review/tense',
  REVIEW_HEARING: '/app/decks/:id/review/hearing',
  MATERIAL_DECKS: '/app/materials/:materialId/decks',
  FORGET_PASSWORD: '/app/forget_password',
  RESET_PASSWORD: '/app/reset_password',
  SETTING: '/app/setting/:type',
  SETTING_ACCOUNT: '/app/setting/account',
  SETTING_PREFERENCE: '/app/setting/preference',
  SETTING_ORDER_HISTORY: '/app/setting/order_history',
  SETTING_SUBSCRIPTION: '/app/setting/subscription',
  ACCOUNT_EXAM_HISTORY: '/app/exam_history',
  ACCOUNT_PROGRESS: '/app/progress',
  ANSWERS_INPUT: '/app/answers-input',
  ADMIN_ANSWERS_INPUT: '/app/admin/answers-input',
  ADMIN_INDEX: '/app/admin',
  ADMIN_IMPORT_STUDENTS: '/app/admin/students/import',
  ADMIN_UPLOAD_QUESTIONS: '/app/admin/upload-questions',
  ADMIN_CLASSES: '/app/admin/classes',
  ADMIN_SCHOOL_TEACHERS: '/app/admin/school_teachers',
  ADMIN_SCHOOL_TEACHERS_ADD: '/app/admin/school_teachers/new',
  ADMIN_MATERIALS: '/app/admin/materials',
  ADMIN_SEATS: '/app/admin/seats',
  SHOP: '/',
  FB_REDIRECT: `${process.env.SELF_DOMAIN}/app/fb_auth`,
  SUCCESS_FORM: '/success-form',

  OLD_WEB_ADMIN_HOME: `${process.env.NEXT_PUBLIC_WORDUP_OLD_WEB_DOMAIN}/app/admin`,

  INTERNAL: '/internal',
  INTERNAL_TAG_PAGE: '/internal/tag-page',
  INTERNAL_SHOP_ORDERS: '/internal/shop/orders',
  INTERNAL_TEACHER_SCHOOL: '/internal/teacher/school',
  INTERNAL_PAGE_COMPONENT: '/internal/page-component',
  INTERNAL_PARTNER_ENTITY: '/internal/partner-entity',
  INTERNAL_USER_ACCOUNT: '/internal/user-account',
  INTERNAL_MATERIALS_AND_ENROLLMENT: '/internal/materials-and-enrollment',
  INTERNAL_COUPONS: '/internal/coupons',
  INTERNAL_PUSH_NOTIFICATION: '/internal/push-notification',
  INTERNAL_COUPONS_CREATE: '/internal/coupons/create',
  INTERNAL_EXPENSES: '/internal/expenses',
  INTERNAL_SHOP_ADS: '/internal/shop-ads',
  INTERNAL_SHOP_ADS_APP_POPUP: '/internal/shop-ads/app-popup',
  INTERNAL_SHOP_ADS_APP_FLOAT_CAMPAIGN: '/internal/shop-ads/float-campaign',
  INTERNAL_SHOP_ADS_APP_LATEST_EVENTS: '/internal/shop-ads/latest-events',
  INTERNAL_SHOP_ADS_APP_POPULAR_EVENTS: '/internal/shop-ads/popular-events',
  INTERNAL_SHOP_ADS_CREATE: '/internal/shop-ads/create',
  INTERNAL_CAMPAIGNS: '/internal/campaigns',
  INTERNAL_CAMPAIGNS_CREATE: '/internal/campaigns/create',
  INTERNAL_PURCHASE_RIGHTS: '/internal/purchase-rights',
  INTERNAL_ROYALTY_RATES: '/internal/royalty-rates',

  TRIAL_EXAM_PAPER: '/trial/exam-paper',

  SUBSCRIPTION_DASHBOARD: '/subscription-dashboard',
  SUBSCRIPTION_DASHBOARD_FULL_TESTS: '/subscription-dashboard/full-tests',
  SUBSCRIPTION_DASHBOARD_WRONG_QUESTION:
    '/subscription-dashboard/wrong-question',
}

export const SHOP_PATH = {
  DETAIL: ({
    productId,
    tab,
    queries = [],
  }: {
    productId: string
    tab?: 'intro' | 'toc' | 'reviews' | 'comments' | 'outcomes' | 'faq'
    queries?: (string | undefined | null)[]
  }) => {
    queries = queries.filter((q) => q)
    const querystring = (tab ? [`tab=${tab}`, ...queries] : queries).join('&')
    return `/product/${productId}${querystring ? `?${querystring}` : ''}`
  },
  COLLECTION_OFFERS: ({ productId }: { productId: string }) =>
    `/collection-offers/${productId}`,
  CART: '/cart',
  BUYING_GROUP_TEMPLATE: '/bg',
  SEARCH_TEMPLATE: '/search',
  RECOMMENDATIONS_RESULT: () => '/search/recommendations/result',
  RECOMMENDATIONS: () => '/search/recommendations',
  TERMS_OF_SERVICE: '/terms-of-service',
  FB_REDIRECT: `${process.env.NEXT_PUBLIC_SELF_DOMAIN}/fb-auth`,
  EVENT_GENERAL: `/sanmin-109-tecc-general`,
  EVENT_STUDENT: `/sanmin-109-tecc`,
  EVENT_TOEIC_MINI_TEST_LIVEABC: `/toeic-mini-test-liveabc`,
  SAMIN_SUBSCRIPTION_PLAN: '/samin-subscription-plan',
  SANMIN_GSAT_MOCK_TEST: '/sanmin-gsat-mock-test',
  CHRISTMAS_CAMPAIGN: '/christmas-campaign',
  REVIEW_2020: '/review-2020',
  REVIEW_2020_USER: '/review-2020/user',
  RATING: '/rating/edit',
  RATING_SUCCESS: '/rating/success',
  CAMPAIGN_HOT_SALE: '/campaign/hot-sale',
  CAMPAIGN_BIZ_ENG: '/campaign/business_english',
  CAMPAIGN_TOEIC_12: '/campaign/toeic_12',
  CAMPAIGN_TIBE2022_WORK: '/campaign/tibe2022_work',
  CAMPAIGN_TIBE2022_ELEMENTARY: '/campaign/tibe2022_elementary',
  CAMPAIGN_TIBE2022_JUNIOR_HIGH: '/campaign/tibe2022_junior_high',
  CAMPAIGN_2021_BTS: '/2021bts',
  CAMPAIGN_JELLA: '/campaign/jella',
  CAMPAIGN_JELLA_GUIDED_LEARNING_PROGRAM:
    '/campaign/jella_guided_learning_program',
  TOPIC: '/topic',
  TEACHING: '/teaching',
  THANK_YOU: '/thank-you',
  OUTCOME: '/outcome',
  TRIAL_QUESTION_GROUP: '/trial/question-group',
  ABOUT_US: '/about',
  HOME: () => {
    if (isAppWebview()) {
      return '/app-home'
    }

    return '/'
  },
  SEARCH: (query: Partial<{ subject: string }> = {}) =>
    `/search${getQueryString({ obj: query })}`,
}
