const safelyParseJson = <T = unknown>(
  json: string | null | undefined,
  fallback: T
): T => {
  let result: T

  try {
    result = JSON.parse(json!) as T
  } catch (err) {
    result = fallback
  }

  return result
}

export default safelyParseJson
