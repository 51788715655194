import { getAnonymousId } from '@/features/tracking/utils'
import { utils as authUtils } from '@/features/auth'
import { isAppWebview } from '@libs-components/utils/device'

const getGtagBasicEventParams = () => {
  return {
    wu_anon_id: getAnonymousId(),
    user_id: authUtils.getUserId(),
    external_id: authUtils.getUserId(),
    is_app: isAppWebview(),
  }
}

export default getGtagBasicEventParams
