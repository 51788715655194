import type { PropsWithChildren } from 'react'
import NavBar from '@/components/layouts/common/navbar'

const CommonLayout = ({ children }: PropsWithChildren) => {
  return (
    <div className='relative min-h-screen bg-white'>
      <NavBar />
      {children}
    </div>
  )
}

export default CommonLayout
