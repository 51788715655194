import { isAppWebview } from '@libs-components/utils/device'
import { utils as authUtils } from '@/features/auth'
import { getAnonymousId } from '@/features/tracking/utils'

const refreshGtagUserData = () => {
  if (typeof gtag !== 'function') return
  gtag('set', 'user_data', {
    is_app: isAppWebview(),
    user_id: authUtils.getUserId(),
    email: authUtils.getUserEmail(),
    wu_anon_id: getAnonymousId(),
  })
}

export default refreshGtagUserData
