import React from 'react'
import styled from '@emotion/styled'

type BaseButtonProps = React.ComponentProps<'button'>

const Base = ({ children, ...props }: BaseButtonProps) => {
  return (
    <Button aria-label="button" {...props}>
      {children}
    </Button>
  )
}

const Button = styled.button`
  appearance: none;
  border: 0;
  outline: 0;
  padding: 0;
  margin: 0;
  border-radius: 0;
  cursor: pointer;
  background-color: transparent;

  &:focus,
  &:active,
  &:focus-visible {
    border: 0;
    outline: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }
`

export { Base, type BaseButtonProps }
