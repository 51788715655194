export const sendTrafficTracking = ({
  wuAnonId,
  userId,
  productId,
  utmSource,
  utmMedium,
  utmCampaign,
  utmContent,
  utmAdSet,
}: {
  wuAnonId: string
  userId?: string
  productId: string
  utmSource?: string
  utmMedium?: string
  utmCampaign?: string
  utmContent?: string
  utmAdSet?: string
}) => ({
  baseURL: process.env.NEXT_PUBLIC_SLS_API_DOMAIN,
  url: `/shops/traffic_tracking`,
  method: 'POST',
  data: {
    anonId: wuAnonId,
    productId,
    timestamp: Date.now(),
    userId,
    utmSource,
    utmMedium,
    utmCampaign,
    utmContent,
    utmAdSet,
  },
})

export const sendLinkUser = ({ wuAnonId, userId }: { wuAnonId: string; userId: string }) => ({
  baseURL: process.env.NEXT_PUBLIC_SLS_API_DOMAIN,
  url: `/shops/linkUser`,
  method: 'POST',
  data: {
    anonId: wuAnonId,
    userId,
    createdAt: Date.now(),
  },
})
