import React from 'react'

import themeVar from '../../../theme/themeVar'
import { ProgressTextPropsType } from '../types'

const COLORS = themeVar.color.palette

const ProgressLeftText = ({
  style,
  currentBuyerCount,
  goalBuyerCount,
  t,
}: ProgressTextPropsType) => {
  const crowdfundingStyle = {
    marginRight: '0.25rem',
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '24.5px',
    letterSpacing: '0.035px',
  }
  const limitedQtyOfferStyle = {
    fontSize: '0.875rem',
    fontWeight: 400,
    lineHeight: '24.5px',
    letterSpacing: '0.035px',
    color:
      currentBuyerCount >= goalBuyerCount
        ? COLORS['grayscale-500']
        : COLORS['grayscale-800'],
  }

  switch (style) {
    case 'crowdfunding': {
      return (
        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
          <div style={{ ...crowdfundingStyle, color: COLORS['grayscale-800'] }}>
            {t('crowdin:crowdfunding.crowdfunding_goal')}
          </div>
          <div style={{ ...crowdfundingStyle, color: COLORS['peach-500'] }}>
            {currentBuyerCount}/{goalBuyerCount}
          </div>
        </div>
      )
    }

    case 'limited_qty_offer': {
      return (
        <div style={limitedQtyOfferStyle}>
          {currentBuyerCount >= goalBuyerCount
            ? t('crowdin:product-detail-page.info.quota-sold-out')
            : t('crowdin:product-detail-page.info.x-amount-left', {
                amount: goalBuyerCount - currentBuyerCount,
              })}
        </div>
      )
    }

    default:
      return null
  }
}

export default ProgressLeftText
