import { compare, CompareOperator } from 'compare-versions'
import store from 'store'
import UAParser from 'ua-parser-js'

type PlatformInfo = {
  platform: 'ios' | 'android'
  version: string
}

export function getWuAppPlatformInfo(): PlatformInfo {
  return store.get('platform')
}

export function isWuAppWebview(
  givenOperator: CompareOperator = '>=',
  givenVersion = '3.0.5'
) {
  if (typeof window !== 'object') return false

  const platformInfo = getWuAppPlatformInfo()

  if (platformInfo) {
    const { platform, version } = platformInfo

    if (!['ios', 'android'].includes(platform)) return false

    return compare(version, givenVersion, givenOperator)
  }

  return false
}

export function isWuAppIosWebview(
  givenOperator: CompareOperator = '>=',
  givenVersion = '3.0.5'
) {
  if (typeof window !== 'object') return false

  const platformInfo = getWuAppPlatformInfo()

  if (platformInfo) {
    const { platform, version } = platformInfo

    if (platform !== 'ios') return false

    return compare(version, givenVersion, givenOperator)
  }

  return false
}

export function isWuAppAndroidWebview({
  givenVersion,
  givenOperator,
}: any = {}) {
  const targetVersion = givenVersion || '3.0.5'
  const operator = givenOperator || '>='
  if (typeof window !== 'object') return false

  const platformInfo = getWuAppPlatformInfo()

  if (platformInfo) {
    const { platform, version } = platformInfo

    if (platform !== 'android') return false

    return compare(version, targetVersion, operator)
  }

  return false
}

export const isAppWebview = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const parser = new UAParser()
  const userAgent = parser.getUA().toLowerCase()
  const safari = /safari/.test(userAgent)
  const isIos = /iphone/.test(userAgent)

  return isIos ? !safari : userAgent.includes('wv')
}

export const isApplePaySupportedOnDevice = () => {
  if (typeof window === 'undefined') {
    return false
  }

  const parser = new UAParser()
  const result = parser.getResult()

  // https://github.com/faisalman/ua-parser-js/issues/182
  const isDesktop =
    result.device.type === undefined ||
    !['wearable', 'mobile'].includes(result.device.type)
  const isSafari = result.browser.name === 'Safari'
  if (isDesktop && isSafari) {
    return true
  }

  const isIOS = result.os.name === 'iOS'
  const isMobile = result.device.type === 'mobile'
  const isTablet = result.device.type === 'tablet'
  if ((isTablet && isIOS) || (isMobile && isIOS)) {
    return true
  }

  return false
}

export const isInClient = () => {
  return typeof window !== 'undefined'
}
