import Timer from 'easytimer.js'
import { useEffect, useRef, useState } from 'react'

type Props = {
  renderDuration: (timer: Timer) => string | React.ReactNode
}

function useCountdown({ renderDuration }: Props) {
  const timerRef = useRef<Timer | null>(null)
  const [duration, setDuration] = useState<string | React.ReactNode>()

  const setupTimer = () => {
    const timer = new Timer()
    timerRef.current = timer

    timer.addEventListener('secondsUpdated', () => {
      let displayed: string | React.ReactNode

      if (typeof renderDuration === 'function') {
        displayed = renderDuration(timer)
      } else {
        displayed = timer.getTimeValues().toString()
      }

      setDuration(displayed)
    })
  }

  const pauseTimer = () => {
    if (!timerRef.current) return
    timerRef.current.pause()
  }

  const startTimer = (startSecond: number, onEnd?: () => void) => {
    if (!timerRef.current) return

    timerRef.current.stop()

    timerRef.current.start({
      countdown: true,
      startValues: { seconds: startSecond },
      target: { seconds: 0 },
    })

    if (typeof onEnd === 'function') {
      timerRef.current.removeEventListener('targetAchieved', onEnd)
      timerRef.current.addEventListener('targetAchieved', onEnd)
    }
  }

  useEffect(() => {
    setupTimer()

    return () => {
      if (timerRef.current) {
        timerRef.current.stop()
        timerRef.current.removeAllEventListeners()
      }
    }
  }, [])

  return { timerRef, duration, startTimer, pauseTimer }
}

export { useCountdown }
