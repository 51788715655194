import React, { useMemo } from 'react'

import { Accordion } from '../accordion'
import { DiamondDivProps, TitleWithIcon } from './title-with-icon'

export interface OrderInfoAccordionProps extends DiamondDivProps {
  title: string
  children: React.ReactNode
  className?: string
  defaultOpen?: boolean
}

export const OrderInfoAccordion = ({
  title,
  diamondIconColor,
  children,
  className,
  defaultOpen,
}: OrderInfoAccordionProps) => {
  const accordionStyles = useMemo(() => ({ padding: '16px 0px' }), [])

  return (
    <Accordion
      defaultOpen={defaultOpen}
      className={className}
      style={accordionStyles}
      title={
        <TitleWithIcon title={title} diamondIconColor={diamondIconColor} />
      }
    >
      {children}
    </Accordion>
  )
}
