import { createTheme } from '@mui/material/styles'

export const theme = createTheme({
  palette: {
    primary: {
      main: '#42badb',
      light: '#7eedff',
      dark: '#008aa9',
      contrastText: '#000000',
    },
    secondary: {
      main: '#383b3d',
      light: '#f6f8fc',
      dark: '#c3c5c9',
      contrastText: '#383b3d',
    },
  },
})
