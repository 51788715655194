import { motion } from 'framer-motion'
import { useEffect, useState, type MouseEvent } from 'react'
import { useTranslation } from 'next-i18next'
import { Text } from '@libs-components/components/text'
import { COOKIE_KEYS } from '@libs-components/utils/cookie-storage'
import { isWuAppWebview } from '@libs-components/utils/device'
import safelyParseJson from '@libs-components/utils/safely-parse-json'
import cookie from '@/libs/cookie'
import { Button } from '@libs-components/components/button/shop-button'

const GDPR_COOKIE_KEY = COOKIE_KEYS.IS_GDPR_MODAL_CONFIRMED + '_shop'

const GDPRModal = () => {
  const { t } = useTranslation()
  const [isShow, setIsShow] = useState(false)

  const onClick = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    e.stopPropagation()
    cookie.set({
      key: GDPR_COOKIE_KEY,
      value: 'true',
      domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN,
    })
    setIsShow(false)
  }

  useEffect(() => {
    const isToggleOpened = !!safelyParseJson(t('crowdin:common.gdpr-modal.toggle-show'), {
      shop: false,
    }).shop
    setIsShow(isToggleOpened && !cookie.get(GDPR_COOKIE_KEY))
  }, [t])

  if (!isShow || isWuAppWebview()) return null

  return (
    <motion.div
      className='z-modal mobileUp:bottom-10 mobileUp:left-10 mobileUp:max-w-[20rem] fixed bottom-4 left-4 right-4'
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 1 }}
    >
      <div className='bg-grayscale-000 rounded-lg p-5 shadow'>
        <Text
          component='p'
          variant='body_s_400'
          className='mb-4'
          dangerouslySetInnerHTML={{ __html: t('crowdin:common.gdpr-message') }}
        />
        <Button className='bg-primary-500 h-9 w-full rounded-full' onClick={onClick}>
          <Text variant='body_m_400' color='grayscale-000'>
            {t('crowdin:common.got-it')}
          </Text>
        </Button>
      </div>
    </motion.div>
  )
}

export { GDPRModal }
