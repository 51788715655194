import { notify } from '@/libs/error-notifier'
import { credential } from './credentials'

export const handleWuAppSignInSuccess = () => {
  const win = window as any
  try {
    if (win.webkit && win.webkit.messageHandlers && win.webkit.messageHandlers.notifySignIn) {
      win.webkit.messageHandlers.notifySignIn.postMessage(credential.get())
    }
  } catch (err) {
    notify({
      err,
      context: {
        key: 'wu app sign in error',
      },
    })
  }
}

export const postSignInSuccess = ({ beRes }: { beRes: any }) => {
  credential.update(beRes)

  handleWuAppSignInSuccess()
}
