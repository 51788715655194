import Script from 'next/script'
import { useEffect } from 'react'

export const InitGoogleAdsense = () => {
  useEffect(() => {
    const script = document.createElement('script')
    script.async = true

    document.head.appendChild(script)

    script.onload = () => {
      try {
        const adsbygoogle = (window as any)?.adsbygoogle || []
        if (adsbygoogle && !adsbygoogle.loaded) {
          adsbygoogle.push({})
        }
      } catch (e) {
        console.error('Adsense error:', e)
      }
    }

    return () => {
      document.head.removeChild(script)
    }
  }, [])

  return (
    <Script
      id='init-adsense'
      strategy='afterInteractive'
      src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7698839233909988'
      crossOrigin='anonymous'
    />
  )
}
