import { isEmpty } from 'lodash-es'

import CookieUtils from '@/libs/cookie'
import {
  getLocalStorageCreds,
  getLocalStoragePlatformInfo,
  removeLocalStorageCreds,
} from '@/libs/localstorage'
import { COOKIE_KEY_CREDENTIAL, COOKIE_KEY_USER_ID, COOKIE_KEY_USER_INFO } from './constants'
import type { Credential } from './types'

const saveCredential = (credential: Credential) => {
  CookieUtils.set({
    key: COOKIE_KEY_CREDENTIAL,
    value: JSON.stringify(credential),
    expires: CookieUtils.LONGEST_EXPIRARY_TIME,
    domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN!,
  })
}

const getCredential = (): Credential | {} => {
  // our app will save credential to localstorage
  const credFromLocalstorage = getLocalStorageCreds() || {}
  let credFromCookie = CookieUtils.get(COOKIE_KEY_CREDENTIAL, true)

  if (!isEmpty(credFromLocalstorage)) {
    const platformInfo = getLocalStoragePlatformInfo()
    const isFromApp = platformInfo && platformInfo.platform
    if (isFromApp) {
      saveCredential(credFromLocalstorage)
      credFromCookie = credFromLocalstorage
    } else {
      // from old version of shop which we used localstorage not cookie
      if (isEmpty(credFromCookie)) {
        saveCredential(credFromLocalstorage)
        credFromCookie = credFromLocalstorage
      }
      removeLocalStorageCreds()
    }
  }

  return credFromCookie || {}
}

const checkHasCredential = () => {
  const cred = getCredential()

  const hasCredential = 'uid' in cred && Boolean(cred.uid)

  return hasCredential
}

const getUserId = () => {
  if (typeof window === 'undefined') return null
  return CookieUtils.get(COOKIE_KEY_USER_ID)
}

const getUserEmail = () => {
  if (typeof window === 'undefined') return null
  return (getCredential() as any)?.uid
}

const saveUserId = (userId: string) => {
  if (typeof window === 'undefined') return null

  return CookieUtils.set({
    key: COOKIE_KEY_USER_ID,
    value: String(userId || ''),
    expires: CookieUtils.LONGEST_EXPIRARY_TIME,
    domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN!,
  })
}

const clearCookies = () => {
  ;[COOKIE_KEY_CREDENTIAL, COOKIE_KEY_USER_ID, COOKIE_KEY_USER_INFO].forEach(key => {
    CookieUtils.del(key, { domain: process.env.NEXT_PUBLIC_WU_SUB_DOMAIN! })
  })
}

export default {
  saveCredential,
  saveUserId,
  getUserId,
  getUserEmail,
  getCredential,
  clearCookies,
  checkHasCredential,
}
