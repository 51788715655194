import themeVar from '../../theme/themeVar'
import { Text } from '../text'
import { Icon } from '../icon'

type Props = {
  text: string
}

const PageDescription = (props: Props) => {
  const { text } = props
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        columnGap: '0.5rem',
        padding: '0.5rem 0.75rem',
        borderRadius: '0.5rem',
        background: `linear-gradient(225deg, ${themeVar.color.palette['primary-500']} 0%, ${themeVar.color.palette['purple-500']} 100%)`,
      }}
    >
      <Icon
        type="featured_seasonal_and_gifts"
        color="grayscale-000"
        style={{ marginTop: '0.25rem' }}
      />
      <Text variant="body_s_400" color="grayscale-000">
        {text}
      </Text>
    </div>
  )
}

export default PageDescription
